<template>
  <div class="register-container minSize">
    <Header></Header>
    <Back @handleBack="handleBack"></Back>
    <div class="register-content">
      <div class="register-form" v-if="!registerStatus">
        <div class="title">{{$t('login.newUser')}}</div>
        <div class="error-tip"></div>
        <div class="register-item first">
          <el-input 
          v-model="myUserName.value" 
          @input="onKeyupUserName"
          autocomplete="new-password"
          :placeholder="$t('login.phone')"

          :maxlength='11'
          clearable
        ></el-input>
        </div>
        <div class="register-item">
          <el-input 
          v-model="myPassword.value" 
          autocomplete="new-password"
          :placeholder="$t('login.pwsTips')"
          clearable
          :minlength='8'
          :maxlength='16'
          :type="typeFlag1"
        >
          <i slot="suffix" class="icon-style" :class="iconEl1" autocomplete="auto" @click="iconFlag1 = !iconFlag1"></i>
        </el-input>
        </div>
        <div class="register-item">
          <el-input 
            v-model="myPassword2.value" 
            :placeholder="$t('login.inputSurePws')"
            clearable
            :minlength='8'
            :maxlength='16'
            :type="typeFlag2"
          >
            <i slot="suffix" class="icon-style" :class="iconEl2" autocomplete="auto" @click="iconFlag2 = !iconFlag2"></i>
          </el-input>
        </div>
        <div class="register-item">
          <el-input 
            v-model="myNickName.value" 
            :placeholder="$t('login.userName')"
            maxlength="25"
            clearable
        ></el-input>
        </div>
        <div class="register-item verification">
          <el-input 
            v-model="myCode.value" 
            @input="codeOnlyNumber"
            :placeholder="$t('login.inputCode')"
            :maxlength="4"
            auto-complete="new-password"
            clearable
          ></el-input>
          <el-button :class="btnDisabled ? 'disabled_style' : 'btn_style'"  type="primary" @click="handleAuthCode" :disabled="btnDisabled">
            {{btntxt}}
            </el-button>
        </div>
        <div class="register-item register">
          <el-button :class="getChecked ? 'disabled_register' : 'register_style'"   @click="handleRegister" :disabled="getChecked">{{$t('login.onLogin')}}</el-button>
        </div>
        <div class="tips">
          <el-checkbox v-model="allowEarlyEntry.checked">{{$t('login.okFine')}}</el-checkbox>
          <a
            href="https://cloud.100doc.com.cn/support/userProtocol"
            target="_blank"
            class="rules"
          >{{$t('login.termService')}}</a>
        </div>
      </div>
      <el-dialog
        custom-class="commonElDialog"
        width="380px"
        :visible.sync="commonElDialog"
      >
        <div class="dialog-title">
          <div class="icon error"></div>
          <div class="text">{{ commonElDialogTitle }}</div>
        </div>

        <div class="dialog-content">
          {{ commonElDialogText }}
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="commonElDialog = false">{{$t('login.know')}}</el-button>
        </span>
    </el-dialog>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// 头部组件
import Header from "@/components/header/Header";
// footer 组件
import Footer from "@/components/footer/Footer";
// 返回组件
import Back from "@/components/back/Back";
// 密码加密组件
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js";
import {checkCode,checkMobile,checkchangePwd} from "@/utils/check";
import { permituserRegist, sendCode } from "@/api/meet"


export default {
  name: "Register",
  components: {
    Header,
    Footer,
    Back,
  },
  data() {
    return {
      iconFlag1:false, //密码眼睛睁闭
      iconFlag2:false, //2次密码眼睛睁闭
      commonElDialog:false,
      commonElDialogTitle: '',
      commonElDialogText: '',
      // 用户名称
      myUserName: {
        value: "",
        type: "text",
      },
      // 密码输入框
      myPassword: {
        value: "",
        type: "password",
      },
      // 确认密码输入框
      myPassword2: {
        value: "",
        type: "password",
      },
      // 用户昵称
      myNickName: {
        value: "",
        type: "text",
      },
      // 验证码
      myCode: {
        value: "",
        type: "text",
      },
      // 阅读同意
      allowEarlyEntry: {checked: true},
      registerStatus: false,
      btntxt: this.$t('login.getCode'),
      timer: null,
      btnDisabled: true,
      count: 60,
      check: false,
    }
  },
  computed: {
    typeFlag1(){
      return this.iconFlag1 ? "test" : "password"
    },
    typeFlag2(){
      return this.iconFlag2 ? "test" : "password"
    },
    iconEl1(){
      return !this.myPassword.value ? "" : this.iconFlag1 ? "el-icon-minus" : "el-icon-view"
    },
    iconEl2(){
      return !this.myPassword2.value ? "" : this.iconFlag2 ? "el-icon-minus" : "el-icon-view"
    },
    getChecked() {
      return !(
        this.myUserName.value !== "" &&
        this.myPassword.value !== "" &&
        this.myPassword2.value !== "" &&
        this.myCode.value !== "" &&
        this.myNickName.value !== "" &&
        this.allowEarlyEntry.checked
      );
    },
  },
  methods: {
    codeOnlyNumber(value) {
      this.myCode.value = value.replace(/[^\d]/g, ""); 
    },
    // 返回
    handleBack() {
      this.$router.push({ name: "login" });
    },
    // 获取验证码
    async handleAuthCode() {
      let that = this;
      if (!checkMobile(that.myUserName.value)) {
        that.commonElDialogText = that.$t('login.try')
        that.commonElDialogTitle = that.$t('login.faildCode')
        that.commonElDialog = true
        return
      }
      let data = {
        phone: this.myUserName.value,
      };

      this.getCode();

      try {
        await sendCode(data)
      } catch (error) {
        console.log(error) // TODO:
      }
    },
    // 验证码倒计时
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.btnDisabled = true;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count = this.count - 1;
            this.btntxt = this.count + this.$t('login.resend');
          } else {
            this.btnDisabled = false;
            clearInterval(this.timer);
            this.btntxt =this.$t('login.getCode');
            this.timer = null;
          }
        }, 1000);
      }
    },
    onKeyupUserName() {
      this.btnDisabled = this.myUserName.value === "";
      this.myUserName.value=this.myUserName.value.replace(/[^\d]/g,'')
    },

    // 清除倒计时
    destroyed() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    // 点击注册
    async handleRegister() {
      let that = this;
      if (that.myPassword.value.length < 8 && that.myPassword2.value.length < 8) {
          that.commonElDialogTitle = that.$t('login.tips')
          that.commonElDialogText = that.$t('reg.lengthPwd')
          that.commonElDialog = true
          return
      }
      if (that.myPassword.value !== that.myPassword2.value) {
          that.commonElDialogTitle = that.$t('login.tips')
          that.commonElDialogText = that.$t('reg.somePwd')
          that.commonElDialog = true
          return
      }
      let data = {
        username: this.myUserName.value,
        password: Base64.stringify(
          CryptoJS.enc.Utf8.parse(sha256(this.myPassword.value).toString())
        ),
        checkCode: this.myCode.value,
        nickname: this.myNickName.value,
      };

      try {
        await permituserRegist(data)

        this.$router.push({ path: "/finishRegister" })
      } catch (error) {
        console.log(error) // TODO:
      }
    }
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .register-content .el-input__inner{
  padding:0;
  border:none;
  border-radius: 0;
  border-bottom: 1px solid #dcdfe6;
  color: #333333;
    &:focus {
        border-color: #1ab370;
      }
}
::v-deep{
  .el-input__suffix-inner{
   flex-direction: row-reverse!important;
   -webkit-flex-direction: row-reverse !important;
   display: flex!important;
  }
}
.register-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .register-content {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 88px;
    flex-direction: column;

    .register-form {
      .title {
        display: inline-block;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        text-align: center;
        padding-bottom: 22px;
      }
      .register-item {
        width: 360px;
        height: 40px;
        margin-top: 16px;
        position: relative;
        ::v-deep .input-text-parent .eyes{
          right: 32px;
        }
        ::v-deep .input-text-parent .input-box{
           padding: 0 20px 0 4px;
        }
        ::v-deep .yb-button{
          width:114px;
          height:30px;
          margin-bottom:5px;
        }
        ::v-deep .el-input__inner::placeholder{
          color: #999999;
          font-size: 14px;
        }
        ::v-deep .el-button{
            padding:0;
          }
      @mixin public-code(){
        position: absolute;
        right: 0;
        bottom: 4px;
        height: 30px;
        border-radius: 4px;
        padding: 6px 10px;
        font-family: PigFangSC-Regular, PingFang SC;
        text-align: center;
        line-height: 10px;
        font-weight: 400;
      }
      .btn_style{
        min-width: 90px;
        width: auto;
        background: rgba(255, 255, 255);
        border: 1px solid #FF8200;
        color:#FF8200;
        @include public-code;
        &:focus{
          background: rgba(255, 130, 0, 0.1);
        }
        &:hover{
          background: rgba(255, 130, 0, 0.2);
        }
      }
      .disabled_style{
        border:none;
        background: #FFDFA3;
        color: #FFFFFF;
        @include public-code;
      }
      }
      .register {
        @mixin public_style($bg){
        display:inline-block;
        width: 360px;
        height: 40px;
        padding:0 10px;
        font-size: 14px;
        color: #fff;
        border-radius: 4px;
        background-color: $bg;
      }
      .disabled_register{
        @include public_style(#b5e6ca);
      }
      .register_style{
        @include public_style(#1ab370);
        &:hover{
          background: #3BBF82;
        }
        &:focus{
          background: #0E8C5A;
        }
      }
      }
      .icon-style{
        margin-top: 14px;
        margin-right: 6px;
        margin: 14px 6px 0 6px;
        font-size: 14px;
      }
    }

    .register-item.first {
      margin-top: 22px;
      color: red;
    }

    .register-item.register {
      margin-top: 40px;
      ::v-deep .yb-button{
        width:360px;
        height:40px;
        font-size:16px;
        }
    }
     .tips {
        display: flex;
        align-items: center;
        margin-top: 20px;
        font-size: 14px;
        ::v-deep .el-checkbox__label{
          color: #666666;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
        .rules {
          color: #1ab370;
          margin-top:4px;
          margin-left:8px;
        }
    }
  }
}
</style>